import {
  IconArrowRight,
  IconListening,
  Link,
  PreStyledComponents,
  NavigationTiles,
  Theme
} from "@life-without-barriers/react-components"

import React from "react"

const { Container } = PreStyledComponents
const { youthFull } = Theme

const pages = [
  {
    title: "Listening to children",
    showReadMore: true,
    href: "/our-approach/child-youth-and-family/listening-to-children/",
    copy: "Children and young people have the right to have their voices heard."
  },
  {
    title: "Listening to families",
    showReadMore: true,
    href: "/our-approach/child-youth-and-family/listening-to-families/",
    copy: "We listen and learn from families to support the best possible outcomes for children and young people in out of home care."
  },
  {
    title: "Listening to carers",
    showReadMore: true,
    href: "/our-approach/child-youth-and-family/listening-to-carers/",
    copy: "We listen to carers and local care teams to understand lived experiences."
  }
]

const heading = (
  <h2 className="fw8 ts-display-5 ts-display-4-ns mr2 flex items-center">
    Learn more about how we listen to the voices of children, family and carers:
  </h2>
)

export const iconSelector = (
  type: string,
  color: string,
  height?: string,
  width?: string
): JSX.Element | undefined => {
  if (type === "listening") {
    return <IconListening color={color} height={height} width={width} />
  }
  return undefined
}
const ListeningToRelatedPages = ({
  currentPathname
}: {
  currentPathname: string
}) => (
  <div className="ph3 ph4-ns pv4 pv5-ns bg-lwb-theme-xxx-light">
    <Container>
      <NavigationTiles
        selectIconFn={iconSelector}
        pages={pages}
        headingIcon={
          <IconListening color={youthFull.medium} height="48" width="48" />
        }
        currentPathname={currentPathname}
        heading={heading}
      />
      <div className="tc mt4 mb2">
        <Link
          className="fw8 color-lwb-theme-dark no-underline"
          to="/our-approach/child-youth-and-family/"
          title="Learn more about our approach"
        >
          Explore our approach - Changing Lives, New Directions in Care{" "}
          <IconArrowRight
            height="8"
            width="5"
            color={youthFull.dark}
            className="ml1"
          />
        </Link>
      </div>
    </Container>
  </div>
)
export default ListeningToRelatedPages
