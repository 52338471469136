import {
  Box,
  Container,
  MarkdownFile,
  Row,
  Section,
  routesObject
} from "@life-without-barriers/react-components"
import MarkdownTemplate, {
  MarkdownTemplateData,
  MarkdownTemplateProps
} from "./markdown-template"

import { IGatsbyImageData } from "gatsby-plugin-image"
import ListeningToRelatedPages from "../../components/our-approach/ListeningToRelatedPages"
import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

interface Data extends MarkdownTemplateData {
  bannerImage: IGatsbyImageData
  footnotes: FootNotes
}

interface OurApproachProps extends MarkdownTemplateProps {
  data: Data
}

interface FootNotes {
  edges: [
    {
      node: {
        html: string
      }
    }
  ]
}

const FootnoteMarkdownFile = styled(MarkdownFile)`
  p {
    font-size: 12px;
    line-height: 16px;
  }
`

const ListeningToTemplate = (props: OurApproachProps) => {
  let footNoteData
  if (props.data.footnotes.edges.length > 0) {
    footNoteData = props.data.footnotes.edges[0].node
  }

  return (
    <MarkdownTemplate
      {...props}
      ogImage={props.data.bannerImage}
      breadcrumbs={[
        routesObject.home,
        {
          to: "/our-approach",
          text: "Our approach",
          title: "Return to parent page"
        },
        {
          to: "..",
          text: "Child, youth and family",
          title: "Return to main page"
        }
      ]}
      afterContent={
        <ListeningToRelatedPages currentPathname={props.location.pathname} />
      }
      footnoteContent={
        footNoteData && (
          <Section background="bg-lwb-grey-xxx-light">
            <Container>
              <Row>
                <Box className="layout-readable">
                  <FootnoteMarkdownFile
                    className="page markdown-file"
                    dangerouslySetInnerHTML={{ __html: footNoteData.html }}
                  />
                </Box>
              </Row>
            </Container>
          </Section>
        )
      }
    />
  )
}

export const query = graphql`
  query ($slug: String, $footnotesPathRegex: String) {
    ...ContentMarkdownQueryFragment
    footnotes: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: $footnotesPathRegex } }
    ) {
      edges {
        node {
          html
        }
      }
    }
    bannerImage: file(
      relativePath: { regex: "/our-approach/img-our-approach-banner.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1200, quality: 90)
      }
    }
  }
`
export default ListeningToTemplate
